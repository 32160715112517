'use strict';

import $ = require("jquery");
import Func from './func';

import View from './view';
import Calendar from './calendar';

$(() => {
 // アカウント窓 開閉
 const Button = new View.Button();

 // サムネイル ホバー
 const PhotoInfo = new View.PhotoInfo();

 // 検索窓
 const Search = new View.Search();

 // カレンダー
 const MyCalendar = new Calendar.MyCalendar();

 // 拡大画面
 if( $('#expand_exist').val() === "1" ){
  const Expand = new View.Expand();
 }

 // フォルダー
 const Folder = new View.Folder();

 // 写真編集画面
 const Edit = new View.Edit();

 // 写真編集画面
 const Process = new View.Process();

});