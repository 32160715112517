'use strict';
import Config from './config';
import Func from './func';
import $ = require("jquery");
//import FormData = require("form-data/lib/form_data");

namespace View {

    // アカウント窓 開閉
    export class Button {
        public el: any;

        constructor(
            private account: any = $('.trigIconAccount'),
            private naviMore: any = $('.trigIconNaviMore'),
            private naviAdmin: any = $('.trigIconNaviAdmin'),
            private edit: boolean = false,
            private del: boolean = false,
            private download: boolean = false,

            private countLimits: {
                edit,
                del,
                download,
            } = {
                edit: 30,
                del: 100,
                download: 30,
            },

            ){

            let self = this;

            this.account.hover( () => {
                $('.bulletIconAccount').show();
            }, function(){
                $('.bulletIconAccount').hide();
            });

            this.naviMore.click( () => {
                return this.viewNaviMore();
            });

            this.naviAdmin.hover( () => {
                $('.bulletIconNaviAdmin').show();
            }, function(){
                $('.bulletIconNaviAdmin').hide();
            });

            $('.trigEdit').click( () => {
                this.toEditMode();
            });

            $('.trigDel').click( () => {
                this.toDelMode();
            });

            $('.trigDownload').click( () => {
                this.toDownloadMode();
            });

            $('#photoes').on("click", '.trigBoxCheck', function(){
                self.el = $(this);
                self.boxCheck();
            });
/*
            $('#photoes').on("click", '.trigBoxEdit', function(){
                self.el = $(this);
                self.checkEdit();
            });

            $(document).on("click", '.trigBoxDel', function(){
                self.el = $(this);
                self.checkDel();
            });

            $(document).on("click", '.trigBoxDownload', function(){
                self.el = $(this);
                self.checkDownload();
            });
*/
            $('.trigCancelEditModes').click( () => {
                this.toEditModeNo();
                this.toDelModeNo();
                this.toDownloadModeNo();
                this.viewExpandButton();
            });

            $('.trigUnSelectAll').click( function(){
                const mode = $(this).data("mode");
                self.unSelectAll(mode);
            });

            $('.trigSubmitEdit').click( () => {
                this.submitBox("edit");
//                this.submitEdit();
            });

            $('.trigSubmitDel').click( () => {
                this.submitBox("del");
//                this.submitDel();
            });

            $('.trigSubmitDownload').click( () => {
                this.submitBox("download");
            });

            $('#select_year').change( () => {
                $('#fiscal_year').submit();
            });

            $('.trigChangeForm').click(function(){
                const form = $(this).data("form");
                self.changeForm(form);
            });

        }

        public viewNaviMore(): boolean {
            if( $('.bulletIconNaviMore').attr("data-open") !== "1" ){
                $('.bulletIconNaviMore').animate(
                    {left: 0},
                    {duration: 300, complete: function(){
                        $(this).addClass("open").attr("data-open", 1);
                    }}
                );
            }else{
                $('.bulletIconNaviMore').animate(
                    {left: -220},
                    {duration: 300, complete: function(){
                        $(this).removeClass("open").attr("data-open", 0);
                    }}
                );
            }

            return false;
        }

        public toEditMode(): void {
            if(this.edit){
                this.toEditModeNo();
            }else{
                this.toEditModeYes();
            }
            this.viewExpandButton();
        }

        public toDelMode(): void {
            if(this.del){
                this.toDelModeNo();
            }else{
                this.toDelModeYes();
            }
            this.viewExpandButton();
        }

        public toDownloadMode(): void {
            if(this.download){
                this.toDownloadModeNo();
            }else{
                this.toDownloadModeYes();
            }
            this.viewExpandButton();
        }

        public viewExpandButton(): void {
            if(this.edit || this.del || this.download){
                $('.bulletButtonExpand').show();
                $('#checking').val(1);
            }else{
                $('.bulletButtonExpand').hide();
                $('#checking').val("");
            }
        }

        public toEditModeYes(): void {
            this.edit = true;
            this.del = false;
            this.download = false;
            $('#photoes').attr("data-edit", "1").attr("data-del", "").attr("data-download", "");
            $('#edit-menu').stop(true).animate({top: 0}, {duration: 240, easing: "swing"});
            $('#del-menu').css({top: -71});
            $('#download-menu').css({top: -71});
        }
        public toEditModeNo(): void {
            this.edit = false;
            $('#photoes').attr("data-edit", "");
            $('#edit-menu').stop(true).animate({top: -71}, {duration: 100, easing: "linear"});
        }

        public toDelModeYes(): void {
            this.edit = false;
            this.del = true;
            this.download = false;
            $('#photoes').attr("data-del", "1").attr("data-edit", "").attr("data-download", "");
            $('#del-menu').stop(true).animate({top: 0}, {duration: 240, easing: "swing"});
            $('#edit-menu').css({top: -71});
            $('#download-menu').css({top: -71});
        }
        public toDelModeNo(): void {
            this.del = false;
            $('#photoes').attr("data-del", "");
            $('#del-menu').stop(true).animate({top: -71}, {duration: 100, easing: "linear"});
        }

        public toDownloadModeYes(): void {
            this.edit = false;
            this.del = false;
            this.download = true;
            $('#photoes').attr("data-download", "1").attr("data-edit", "").attr("data-del", "");
            $('#download-menu').stop(true).animate({top: 0}, {duration: 240, easing: "swing"});
            $('#edit-menu').css({top: -71});
            $('#del-menu').css({top: -71});
        }
        public toDownloadModeNo(): void {
            this.download = false;
            $('#photoes').attr("data-download", "");
            $('#download-menu').stop(true).animate({top: -71}, {duration: 100, easing: "linear"});
        }


        public boxCheck(): any {
            let el, action;
            if(this.edit){
                el = this.el.find('.bulletBoxEdit');
                action = "edit";
            }else if(this.del){
                el = this.el.find('.bulletBoxDel');
                action = "del";
            }else if(this.download){
                el = this.el.find('.bulletBoxDownload');
                action = "download";
            }
            if(el && action){
                if( el.attr("data-"+action) === "1" ){
                    el.attr("data-"+action, "");
                }else{
                    const target = '.bulletBoxEdit[data-' + action + '="1"]';
                    if($(target).length >= this.countLimits[action]){
                        alert("一度に選択できる個数は"+this.countLimits[action]+"件までです。");
                        return false;
                    }else{
                        el.attr("data-"+action, "1");
                    }
                }
            }

        }

/*
        public checkEdit(): void {
            const edit = this.el.attr("data-edit");
            if( edit === "1" ){
                this.el.attr("data-edit", "");
            }else{
                this.el.attr("data-edit", "1");
            }
        }
        public checkDel(): void {
            const del = this.el.attr("data-del");
            if( del === "1" ){
                this.el.attr("data-del", "");
            }else{
                this.el.attr("data-del", "1");
            }
        }
        public checkDownload(): void {
            const download = this.el.attr("data-download");
            if( download === "1" ){
                this.el.attr("data-download", "");
            }else{
                this.el.attr("data-download", "1");
            }
        }
*/

        public unSelectAll(mode: string): void {
            $('.photo__box-' + mode).attr("data-" + mode, "");
        }

        public checkCountLimits(action, photoes): boolean {
            if(photoes.length > this.countLimits[action]){
                alert("一度に選択できる個数は"+this.countLimits[action]+"件までです。");
                return false;
            }else{
                return true;
            }
        }


        public submitBox(action){
            let target;
            if(action === "edit"){
                target = '.bulletBoxEdit[data-edit="1"]';
            }else if(action === "del"){
                target = '.bulletBoxDel[data-del="1"]';
            }else if(action === "download"){
                target = '.bulletBoxDownload[data-download="1"]';
            }
            const photoes = $(target);
            if( !this.checkCountLimits(action, photoes) ){
                return false;
            }else{
                const body = this.createFormElements(photoes);
                if(body === ""){
                    return false;
                }else{
                    $('#frm-'+action).append(body);
                    document.forms['frm-'+action].submit();
                }
            }
        }

/*
        public submitEdit(): any {
            const photoes = $('.bulletBoxEdit[data-edit="1"');
            if(photoes.length > 10){
                alert("一度に選択できる個数は10件までです。");
                return false;
            }
            const body = this.createFormElements(photoes);

            if(body === ""){
                return false;
            }else{
                $('#frm-edit').append(body);
                document.forms['frm-edit'].submit();
            }
        }

        public submitDel(): any {
            const photoes = $('.bulletBoxDel[data-del="1"');
            if(photoes.length > 100){
                alert("一度に選択できる個数は100件までです。");
                return false;
            }
            const body = this.createFormElements(photoes);

            if(body === ""){
                return false;
            }else{
                $('#frm-del').append(body);
                document.forms['frm-del'].submit();
            }
        }
*/
        public createFormElements(photoes): string {
            let body = "";
            Object.keys(photoes).forEach( function(i){
                const photoId = photoes.eq( Number(i) ).parent().parent().attr("data-id");
                if(photoId){
                    body += '<input type="hidden" name="photoes[]" value="' + photoId + '">';
                }
            });

            return body;
        }

        public changeForm(form): void {
            if(form === "all"){
                $('.trigChangeForm[data-form=by_one]').removeClass("btn-success").attr("data-select", 0);
                $('.trigChangeForm[data-form=all]').addClass("btn-success").attr("data-select", 1);
                $('.bulletChangeFormByOne').hide();
                $('.bulletChangeFormAll').show();
            }else{
                $('.trigChangeForm[data-form=by_one]').addClass("btn-success").attr("data-select", 1);
                $('.trigChangeForm[data-form=all]').removeClass("btn-success").attr("data-select", 0);
                $('.bulletChangeFormByOne').show();
                $('.bulletChangeFormAll').hide();
            }
        }

    }




    export class PhotoInfo {
        public el: any;
        public layout: any;

        constructor(
            ){

            let self = this;
            $(document).on("mouseover", '.trigPhotoInfo', function(){
                self.el = $(this);
                self.showPhotoInfo();
            });

            $(document).on("mouseleave", '.trigPhotoInfo', function(){
                self.el = $(this);
                self.hidePhotoInfo();
            });

            $('.trigViewLayout').click(function(){
                self.layout = $(this);
                return self.changeViewLayout();
            });

        }

        public showPhotoInfo(): void {
            this.el.children('.bulletPhotoInfo').show();
        }

        public hidePhotoInfo(): void {
            this.el.children('.bulletPhotoInfo').hide();
        }

        public changeViewLayout(): boolean {
            const layout = this.layout.data("layout");
            $('.bulletViewLayout').attr("data-layout", layout);
            $('.trigViewLayout').removeClass("select");
            this.layout.addClass("select");

            return false;
        }

    }


    export class Search {
        public el: any;

        constructor(
            private ajaxing: boolean = false,
            private search: any = $('.trigSearch'),
            private box: any = $('.bulletSearch'),
            private calendar = {year: 0, month: 0, day: 0},
            ){

            let self = this;

            this.search.click( function(){
                const disabled = $(this).data("disabled");
                if(disabled === "disabled"){
                    return false;
                }else{
                    return self.viewSearchBox();
                }
            });

            $(document).on("click", 'body', (e) => {
                if( $.contains( document.getElementById('search-box'), e.target)){
                }else{
                    this.closeSearchBox();
                }
            });

            $('.trigSearchFreeText').blur( () => {
                const elements = this.getSearchElements();
                this.searchMore(elements, "");
            });

            // カレンダーの日付クリック
            $('#calendar').on("click", "td", function(){
                self.el = $(this);

                const target = $('#calendar').attr("data-target");
                self.calendar.year = Number( $(this).attr("data-year") );
                self.calendar.month = Number( $(this).attr("data-month") );
                self.calendar.day = Number( $(this).attr("data-day") );
                self.enterDate(target);

                const elements = self.getSearchElements();
                self.searchMore( elements, target );
            });

            $('.trigSearchMore').click( () => {
                const elements = self.getSearchElements();
                self.searchMore( elements, "");
            });

            $(window).keydown( (e) => {
                if(e.keyCode === 13){
                    if( document.activeElement.id != "submit-search"){
                        e.preventDefault();
                        return false;
                    }
                }
            });
        }

        // カレンダーの日付クリックで日付を入れる
        public enterDate(target): void {
            if(this.el.attr("data-null") !== "1"){
                const date = Number(this.el.html());
                const val = this.calendar.year + "/" + this.calendar.month + "/" + date;
                $('#date_' + target).val(val);
                $('#hide_date_' + target).val(val);
                $('.bulletCalendar').hide().attr("data-show", "0");
            }
        }



        public viewSearchBox(): boolean {
            if(this.box.attr("data-view") !== "1"){
                this.box.show().attr("data-view", "1");
                const ch = this.box.height();
                const wh = $(window).height();
                this.box.css({top: Math.floor((wh - ch) / 2)});
            }else{
                this.box.hide().attr("data-view", "0");
                $('.bulletCalendar').hide().attr("data-show", "0");
            }

            return false;
        }

        public closeSearchBox(): void {
            this.box.hide().attr("data-view", "0");
            $('.bulletCalendar').hide().attr("data-show", "0");
        }

        public freeTextToAry(freeText){
            const text = freeText.replace(/　/g, " ");
            const textAry = text.split(" ");
            let words = [];
            Object.keys( textAry ).forEach( function(i){
                if(textAry[ Number(i) ] !== "" && textAry[ Number(i) ] !== " "){
                    words.push(textAry[ Number(i) ]);
                }
            });

            return words;
        }

        public getSearchElements(){
            const freeText = $('#free_text').val();
            const words = this.freeTextToAry(freeText);

            const dateStart = $('#date_start').val();
            let starts = this.dateStrToObj(dateStart);
            const dateClose = $('#date_close').val();
            let closes = this.dateStrToObj(dateClose);

            const organizationsChecked = $('.bulletOrganizationId:checked');
            let organizations = [];
            Object.keys(organizationsChecked).forEach( function(i){
                if( organizationsChecked.eq( Number(i) ).val() ){
                    organizations.push( organizationsChecked.eq( Number(i) ).val() );
                }
            });

            const D = {
                words: words,
                starts: starts,
                closes: closes,
                organizations: organizations
            };

            return D;
        }

        public searchMore( elements, target ){
            if(target == "start"){
                elements.starts = {
                    year: this.calendar.year,
                    month: this.calendar.month,
                    day: this.calendar.day
                };
            }else if(target == "close"){
                elements.closes = {
                    year: this.calendar.year,
                    month: this.calendar.month,
                    day: this.calendar.day
                };
            }

            this.ajaxSearchMore( elements );
        }

        public dateStrToObj(dateStr){
            const dates = dateStr.split("/");
            const keys = ["year", "month", "day"];
            let datas = {};
            Object.keys( dates ).forEach( function(i){
                datas[ keys[i] ] = dates[i];
            });

            return datas;
        }

        /**
         * @param obj {
             words: array(str, str...),
             starts: obj(year: Y, month: n, day: j),
             closes: obj(year: Y, month: n, day: j),
             organizations: array(str, str, str...)
             }
         */
        public ajaxSearchMore(elements): void{
            let self = this;
            const D = elements;
            console.log(D);

            self.ajaxing = true;
            const token: string = $('meta[name="csrf-token"]').attr('content');
            $.ajax({
                headers: {'X-CSRF-TOKEN': token},
                type: "POST",
                url: "/ajax/search_more",
                data: D,
                cache: false,
                beforeSend: function(){
                    // 実行中画面
                    $('#ajaxing-search').show();
                },
                success: function( data ){
//                    console.log(data);
                    $('#search__result__count__value').html(data);
                },
                complete: function(){
                    // 実行中画面を消す
                    $('#ajaxing-search').hide();
                    self.ajaxing = false;
                }
            });
        }

    }


    export class Expand {

        constructor(
            private ajaxing: boolean = false,
            private lastNum: number = 0,
            private addCount: number = 50, // 1回に読み込む個数
            private windowSize: {
                width: number,
                height: number,
            } = {
                width: 0,
                height: 0,
            },
            private contentSize: {
                width: number,
                height: number,
            } = {
                width: 0,
                height: 0,
            },
            private boxes: any = [],
            private num: number = 1, // 1~
            private show: boolean = false,
            private url: string = 'http://img.sjp-osaka-sandai-museum.com',
            ){

            this.refreshWindowSize();
            this.refreshContentSize();

            $(window).resize( () => {
                this.refreshWindowSize();
                this.refreshContentSize();
            });

            this.getElements();
            this.lastNum = (this.boxes.length - 1);
            this.addCount = Number($('#add_count').val());

            let self = this;

            $('#photoes').on("click", '.trigOpenExpand', function(e){
                if( $(e.target).hasClass("trigOpenExpandForce") ){
                    console.log("hoge");
                    const num = Number( $(e.target).parent().parent().attr("data-num") );
                    console.log(num);
                    if(num){
                        self.num = num;
                        self.refreshExpand();
                        self.showExpand();
                    }

                }else if( $('#checking').val() !== "1" ){
                    const num = Number( $(this).attr("data-num") );
                    if(num){
                        self.num = num;
                        self.refreshExpand();
                        self.showExpand();
                    }
                }
            });

            $('.trigCloseExpand').click( (e) => {
                if( e.target != document.getElementById('expand_box')
                    && !$.contains( document.getElementById('expand_box'), e.target)){
                    $('#expand').hide();
                    this.show = false;
                }
            });
            $('.trigButtonCloseExpand').click( () => {
                $('#expand').hide();
                this.show = false;
            });

            // 前の画像
            $('.trigPrevExpand').click( () => {
                this.prevExpand();
            });

            // 次の画像
            $('.trigNextExpand').click( () => {
                this.nextExpand();
            });

            /**
             *   38 ↑
             * 37 ←   → 39
             *   40 ↓
             * 32: space
             * 27: ESC
             */
            $(window).keydown( (e) => {
                if(this.show){
                    if(e.keyCode === 37){
                        this.prevExpand();
                    }else if(e.keyCode === 39 || e.keyCode === 32){
                        this.nextExpand();
                        return false;
                    }else if(e.keyCode === 27){
                        $('#expand').hide();
                    }
                }
            });

            // 画面スクロールでサムネイル足す
            $(window).scroll( () => {
                if($('#photoes').length > 0){
                    let add = false;
                    const dataCount = Number($('#data_count').val());
                    if(dataCount > 0){
                        if( dataCount > this.boxes.length){
                            add = true;
                        }
                    }else{
                        add = true;
                    }

                    if( $('#photoes').data('add') == "0"){
                        add = false;
                    }

                    if(add){
                        const page = $('#photoes').data('page');
                        this.addPhotoes(page);
                    }
                }
            });
        }

        public refreshWindowSize(): void {
            this.windowSize.width  = Number( window.innerWidth );
            this.windowSize.height = Number( window.innerHeight );
        }

        public refreshContentSize(): void {
            this.contentSize.width  = Number( $('#photoes').width() );
            this.contentSize.height = Number( $('#photoes').height() );
        }

        public addPhotoes(page: string): void {
            if( !this.ajaxing ){
                const sc = Number( $(window).scrollTop() );
                if(sc + this.windowSize.height > this.contentSize.height){
                    this.ajaxAddPhotoes(page);
                }
            }
        }

        public ajaxAddPhotoes(page: string): void {
            let self = this;

            self.ajaxing = true;
            const token: string = $('meta[name="csrf-token"]').attr('content');
            let D = {num: self.lastNum, add: self.addCount};

            $.ajax({
                headers: {'X-CSRF-TOKEN': token},
                type: "POST",
                url: '/ajax/add_photoes_' + page,
                data: D,
                cache: false,
                beforeSend: function(){
                    // 実行中画面
                    $('#ajaxing').css({ visibility: "visible"});
                },
                success: function( data ){
//                    console.log(data);
                    $('#photoes').append(data);
                },
                complete: function(){
                    const boxes = $('.photo__box');
                    for(let i:number = 0; i < self.addCount; i++){
                        let I = i + self.lastNum;
                        const BOX = boxes.eq(I);
                        if(BOX.data("photo")){
                            const box = self.getElement( BOX );
                            const num = Number( BOX.attr("data-num") );
                            self.boxes[num] = box;
                        }
                    }
//                    console.log(boxes);
//                    console.log(self.boxes);

                    // 実行中画面を消す
                    $('#ajaxing').css({ visibility: "hidden"});
                    self.ajaxing = false;
                    self.lastNum += self.addCount;
                    self.refreshContentSize();
                    //console.log(self.lastNum);
                }
            });
        }

        public getElements(): any {
            const boxes = $('.photo__box');
            let self = this;
            Object.keys( boxes ).forEach( function(i){
                const I = Number(i);
                const BOX = boxes.eq(I);
                if(BOX.data("photo")){
                    const box = self.getElement( BOX );
                    const num = Number( BOX.attr("data-num") );
                    self.boxes[num] = box;
                }
            });
        }

        public getElement( BOX ): any {
            const box = {id: "", title: "", date: "", organization: "", photo: "", tags: "", ext: "", folder: ""};
            box.id = BOX.attr("data-id");
            box.title = BOX.attr("data-title");
            box.date = BOX.attr("data-date");
            box.organization = BOX.attr("data-organization");
            box.photo = BOX.attr("data-photo");
            box.ext = BOX.attr("data-ext");
            box.tags = BOX.find('.targetTags').html();
            box.folder = BOX.find('.targetFolder').html();

            return box;
        }

        public refreshExpand(): void {
            const box = this.boxes[this.num];
            $('#expand-title').html(box.title);
            $('#expand-date').html(box.date);
            $('#expand-organization').html(box.organization);
            $('#expand-photo').css( {backgroundImage: 'url(' + this.url + box.photo + '_lg.' + box.ext + ')'});
            $('#expand_box').attr("data-num", this.num);
            $('#expand-tags').html(box.tags);
            $('#expand-folder').html(box.folder);
            $('#expand-href').attr("href", '/photo/download/' + box.id);
        }

        public showExpand(): void {
            $('#expand').show();
            this.show = true;
        }

        public prevExpand(): void {
            this.num--;
            if(this.num <= 0){
                this.num = 1;
            }else{
                this.refreshExpand();
            }
        }

        public nextExpand(): void {
            this.num++;
            if(this.num >= this.boxes.length){
                this.num = this.boxes.length - 1;
            }else{
                this.refreshExpand();
            }
        }
    }


    export class Folder {
        public el: any;
        public layout: any;

        constructor(
            ){

            let self = this;
            $(document).on("dblclick", '.trigGoNextFolder', function(){
                self.el = $(this);
                self.goNextFolder();
            });

            $('.trigGoParentFolder').click( () => {
                this.goParentFolder();
            });

            $('.trigGoOtherFolder').click( function(){
                self.el = $(this);
                self.goOtherFolder();
            });
        }

        public goNextFolder(): void {
            const num = this.el.data("num");
            const folder = this.el.data("folder");
            $('#folder' + num).val(folder);

            document.forms['frm'].submit();
        }

        public goParentFolder(): void {
            for( let i=6; i>0; i--){
                if( $('#folder' + i).val() ){
                    $('#folder' + i).val("");
                    break;
                }
            }

            document.forms['frm'].submit();
        }

        public goOtherFolder(): void {
            const num: number = Number(this.el.data("num"));
            for( let i=6; i>num; i--){
                $('#folder' + i).val("");
            }

            document.forms['frm'].submit();
        }
    }



    export class Edit {
        public el: any;
        public uploadSizeLimitImage: number = 15000000;
//        public uploadSizeLimitZip: number = 1000000000;
        public uploadSizeLimitZip: number = 2000000000;
        public enableExtensionsImage = ['jpg', 'jpeg', 'gif', 'png'];
        public enableExtensionsZip = ['zip'];
        private ext: string = 'jpg';
//        private lastDragEvent: string = "leave";

        constructor(
            private ajaxing: boolean = false,
            ){

            let self = this;

            $('.trigDelCurrentTag').click( function() {
                self.el = $(this);
                self.delCurrentTag();
            });

            // フェイクのファイル選択ボタン
            $('.trigUploadFileBtn').click( () => {
                $('#bulletFakeInput').click();
                return false;
            });

            // ファイルをアップロード
            $('#bulletFakeInput').change( function(){
                // ファイル情報を取得
                const files = this.files;
                const target = $(this).data("target");
                const checked = self.checkUploadFile(files, target);
                if(checked.result){
                    if( target == "image"){
                        self.uploadFile(files[0], target);
                    }else{
                        if(self.confirmFileInfo(files[0])){
                            self.uploadFile(files[0], target);
                        }else{
                            return false;
                        }
                    }
                }else{
                    alert(checked.errorMessage);
                    return false;
                }
            });

            // ドラッグドロップからの入力
            $(window).bind("drop", function (e) {
                // false を返してデフォルトの処理を実行しないようにする
                return false;
            })
            .bind("dragenter", function () {
                $('#dragEnter').show();
                // false を返してデフォルトの処理を実行しないようにする
                return false;
            })
            .bind("dragover", function () {
                // false を返してデフォルトの処理を実行しないようにする
                return false;
            })
            .bind("dragleave", function () {
                // false を返してデフォルトの処理を実行しないようにする
                return false;
            });

            // ドラッグドロップからの入力
            $('#dragEnter').bind("drop", function (event: JQueryEventObject) {
                $('#dragEnter').hide();
                // ドラッグされたファイル情報を取得
                const dragEvent = <DragEvent>event.originalEvent,
                    dataTransfer = dragEvent.dataTransfer,
                    files = dataTransfer.files;
                const target = $('#bulletFakeInput').data("target");
                const checked = self.checkUploadFile(files, target);

                if(checked.result){
                    if( target == "image"){
                        self.uploadFile(files[0], target);
                    }else{
                        if(self.confirmFileInfo(files[0])){
                            self.uploadFile(files[0], target);
                        }else{
                            return false;
                        }
                    }
                }else{
                    alert(checked.errorMessage);
                }
                // false を返してデフォルトの処理を実行しないようにする
                return false;
            })
            .bind("dragenter", function () {
                // false を返してデフォルトの処理を実行しないようにする
                return false;
            })
            .bind("dragover", function () {
                // false を返してデフォルトの処理を実行しないようにする
                return false;
            })
            .bind("dragleave", function () {
                $(this).hide();
                // false を返してデフォルトの処理を実行しないようにする
                return false;
            });

            $('#dragEnter').bind("mouseleave click", () => {
                $('#dragEnter').hide();
            });

            // SUBMIT ボタン押した時に処理中画面表示
            $('.trigGoNext').click( () => {
                $('#ajaxing').show();
            });

        }

        public delCurrentTag(): void {
            const tag = this.el.parent().find('.bulletDelCurrentTag').html();
            const tags = this.el.parent().parent().parent().find('.bulletCurrentTag').val();
            const newTag = tags.replace(' ' + tag + ' ', ' ');
            this.el.parent().parent().parent().find('.bulletCurrentTag').val( newTag );
            this.el.parent().remove();
        }

        /**
         * @param obj {name, size, type, lastModified, lastModifiedDate}
         * @return boolean
         */
        public checkUploadFile(files, target): any {
            let result = true;
            let errorMessage = "";
            if(files.length == 0){
                result = false;
                errorMessage = "ファイルがありません";
            }else{
                files = files[0];
                this.ext = Func.getExtention(files.name);
                let extensions = [];
                let limit = 0;
                if( target == "image"){
                    extensions = this.enableExtensionsImage;
                    limit = this.uploadSizeLimitImage;
                }else{
                    extensions = this.enableExtensionsZip;
                    limit = this.uploadSizeLimitZip;
                }
                if( !Func.inArray(this.ext, extensions)){
                    result = false;
                    errorMessage = "\"" + this.ext + "\" is invalid!\n使用できる拡張子は " + extensions.join("、") + " です";
                }else if(files.size > limit){
                    result = false;
                    errorMessage = "FIle size \""+ Func.unitFormat(files.size) +"\" is too large!\nファイルサイズは " + Func.unitFormat( limit ) + " 以内にして下さい";
                }
            }

            return {result: result, errorMessage: errorMessage};
        }

        /**
         * @param obj {name, size, type, lastModified, lastModifiedDate}
         * @param string
         * @return boolean
         */
        public uploadFile(files, target): void{
            let fd = new FormData();
            fd.append("file", files);
            fd.append('upload_id', $('#uploadId').val());
            fd.append('ext', this.ext);
            fd.append('name', files.name);
            fd.append('target', target);

            this.ajaxUploadFile(fd, target, files.name);
        }


        public ajaxUploadFile(fd, target, filename): void{
            let self = this;
            self.ajaxing = true;
            const token: string = $('meta[name="csrf-token"]').attr('content');

            $.ajax({
                headers: {'X-CSRF-TOKEN': token},
                url: '/ajax/upload_file',
                type: 'post',
                data: fd,
                cache: false,
                processData: false,
                contentType: false,
                //dataType: 'json',
                beforeSend: function(){
                    // 実行中画面
                    $('#ajaxing').show();
                },
                success: function( data ){
                    console.log(data);
                    if(target == "image"){
                        $('#uploadedFile').css('background-image', 'url(/tmp/'+ data +')');
                    }else{
                        $('#bulletFakeInput').val("");
                        $('#originalFilename').val(filename);
                        $('#frm').submit();
                    }
                },
                complete: function(){
                    if(target == "image"){
                        // 実行中画面を消す
                        $('#ajaxing').hide();
                        self.ajaxing = false;
                    }
                }
            });
        }

        public confirmFileInfo(files): boolean {
            let body = '';
            body += 'ファイル名：' + files.name + "\n";
            body += 'サイズ：' + Func.unitFormat(files.size);
            body += "\n";
            body += "このファイルでよろしければアップロードを行います";

            if( !window.confirm(body) ){
                return false;
            }else{
                return true;
            }
//            $('#bulletZipFileInfo').html(body);
        }
    }



    export class Process {
        // 繰り返し実行するオブジェクト
        private IntervalFunc: any;

        constructor(
            private datas = [""],
            ){
            const self = this;

            this.getDatas();
            //console.log(this.datas);

            if(this.datas.length > 0){
                // 処理
                this.do();
            }
        }

        // 1秒ごとに s3_tasks の processing を取得
        public do(): void {
            // 継続時間計測
            this.IntervalFunc = setInterval( () => {
                this.getProcessing();
            }, 1000);
        }

        public getProcessing() {
            this.ajaxGetProcessing();
        }

        public ajaxGetProcessing() {
            let self = this;
            const token: string = $('meta[name="csrf-token"]').attr('content');
            const D = {ids: this.datas};

            $.ajax({
                headers: {'X-CSRF-TOKEN': token},
                url: '/ajax/get_processing',
                type: 'post',
                data: D,
                cache: false,
                success: function( data ){
                    let continues = false;
                    const datas = JSON.parse(data);
                    Object.keys( datas ).forEach( function(id){
                        const myData = datas[id];
                        //console.log(myData);
                        self.updateProcessBar(id, myData);
                        if(Number(myData.processing) != 100){
                            continues = true;
                            self.updateProcessStart(id, myData);
                        }else{
                            self.completeProcess(id, myData);
                        }
                    });

                    if(!continues){
                        //console.log("O WA RI");
                        clearInterval( self.IntervalFunc );
                    }
                }
            });
        }

        public updateProcessBar(id, myData): void{
            $('.bulletProcessStr[data-num="' + id + '"]').html(myData.processing + ' %');
            $('.bulletProcessBar[data-num="' + id + '"]').attr('data-process', myData.processing);
        }

        public updateProcessStart(id, myData): void{
            if(Number(myData.processing) != 0){
                $('.bulletProcessStarted[data-num="' + id + '"]').html(myData.started_at);
            }
        }

        public completeProcess(id, myData){
            let date = new Date();
            $('.bulletProcessClosed[data-num="' + id + '"]').html( Func.dateFormat(date) );
            $('.bulletProcessStr[data-num="' + id + '"]').html('完了');
            const btn = '<span class="process__box__cancel__box"><a class="btn btn-success" href="/photoes/undo/' + myData.upload_id + '">一括登録したファイル一覧</a></span>';
            $('.bulletCancelButton[data-num="' + id + '"]').html(btn);
            $('.bulletProcessBar[data-num="' + id + '"]').removeClass('active progress-bar-striped');
        }

        public getDatas(): void {
            this.datas = [];
            let self = this;
            const datas = $('.trigProcess[data-close="0"][data-cancel="0"]');
            if(datas.length > 0){
                Object.keys(datas).forEach(function(num){
                    const id = datas.eq(Number(num)).data('num');
                    if(id){
                        self.datas.push(id);
                    }
                });
            }
        }
    }


}
export default View;


